import { render, staticRenderFns } from "./editCashDetaile.vue?vue&type=template&id=d96c7aba&scoped=true"
import script from "./editCashDetaile.vue?vue&type=script&lang=js"
export * from "./editCashDetaile.vue?vue&type=script&lang=js"
import style0 from "./editCashDetaile.vue?vue&type=style&index=0&id=d96c7aba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d96c7aba",
  null
  
)

export default component.exports