<template>
  <div>
    <span style="margin-top:5px;margin-right:10px;color:#909399;font-size:12px;">{{remark}}</span>
    <el-button @click="showActiveTag"  type="text">查看示例</el-button>

		<el-dialog title="查看示例" :visible.sync="activeTagVisible"  width="800px" class="dialog data-dialog">
      <div class="list">
        <div class='item'>
          <p>商品详情页</p>
          <img src='http://cdn.dkycn.cn/images/melyshop/activity-tag-goods-share.png'/>
        </div>
        <div  class='item'>
          <p>商品分享图片</p>
          <img src='http://cdn.dkycn.cn/images/melyshop/activity-tag-goods-detaile.png'/>
        </div>
        <div class='item'>
          <p>商品列表</p>
          <img src='http://cdn.dkycn.cn/images/melyshop/activity-tag-goods-list.png'/>
        </div>
      </div>
		</el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    remark: {
      type: String,
      default: '活动期间显示在商品详情页、商品分享图片、商品列表'
    }
  },
  data () {
    return{
      activeTagVisible:false,
    }
  },
  methods: {
    showActiveTag(){
      this.activeTagVisible = true
    },
  }
}
</script>

<style lang="less" scoped>
.list{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .item{
    text-align: center;
    img{
      border: 1px solid #ddd;
      width:220px;
      object-fit: contain;
    }
  }
}
::v-deep .el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
</style>